// Generated by Framer (1e08357)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rBTPaj3Q5", "FYV1K5_qU"];

const serializationHash = "framer-hJFfi"

const variantClassNames = {FYV1K5_qU: "framer-v-hr043l", rBTPaj3Q5: "framer-v-oojrok"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Closed: "rBTPaj3Q5", Open: "FYV1K5_qU"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "rBTPaj3Q5"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "rBTPaj3Q5", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-oojrok", className, classNames)} data-framer-name={"Closed"} layoutDependency={layoutDependency} layoutId={"rBTPaj3Q5"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({FYV1K5_qU: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><motion.div className={"framer-j2uz9h"} layoutDependency={layoutDependency} layoutId={"v3DX1OxWG"} style={{rotate: 0}} variants={{FYV1K5_qU: {rotate: -180}}}><SVG className={"framer-2x0gdv"} data-framer-name={"Icon"} layout={"position"} layoutDependency={layoutDependency} layoutId={"pawpuWLXr"} opacity={1} style={{backgroundColor: "var(--token-5b65ef1f-32d0-4dfc-9999-7805f98b8d97, rgb(29, 131, 58))"}} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 14 14\"><path d=\"M 3 5.5 L 7 9.5 L 11 5.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"rgb(255, 255, 255)\" stroke-linecap=\"round\" stroke-linejoin=\"round\"></path></svg>"} svgContentId={10033508650} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hJFfi.framer-4xvuep, .framer-hJFfi .framer-4xvuep { display: block; }", ".framer-hJFfi.framer-oojrok { height: 14px; overflow: visible; position: relative; width: 14px; }", ".framer-hJFfi .framer-j2uz9h { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }", ".framer-hJFfi .framer-2x0gdv { flex: none; height: 14px; left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"FYV1K5_qU":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framernj7yO1dvM: React.ComponentType<Props> = withCSS(Component, css, "framer-hJFfi") as typeof Component;
export default Framernj7yO1dvM;

Framernj7yO1dvM.displayName = "Caret";

Framernj7yO1dvM.defaultProps = {height: 14, width: 14};

addPropertyControls(Framernj7yO1dvM, {variant: {options: ["rBTPaj3Q5", "FYV1K5_qU"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framernj7yO1dvM, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})